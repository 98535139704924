import(/* webpackMode: "eager" */ "/opt/app/web/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/opt/app/web/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Urbanist\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"urbanist\"}");
import(/* webpackMode: "eager" */ "/opt/app/web/node_modules/react-toastify/dist/react-toastify.esm.mjs");
import(/* webpackMode: "eager" */ "/opt/app/web/node_modules/react-toastify/dist/ReactToastify.css");
import(/* webpackMode: "eager" */ "/opt/app/web/node_modules/@fontsource-variable/dm-sans/index.css");
import(/* webpackMode: "eager" */ "/opt/app/web/src/components/layouts/breadcrumbwraper.tsx");
import(/* webpackMode: "eager" */ "/opt/app/web/src/components/layouts/footer/footer.tsx");
import(/* webpackMode: "eager" */ "/opt/app/web/src/components/layouts/footerwrapper.tsx");
import(/* webpackMode: "eager" */ "/opt/app/web/src/components/layouts/header/header.tsx");
import(/* webpackMode: "eager" */ "/opt/app/web/src/components/layouts/headerwrapper.tsx");
import(/* webpackMode: "eager" */ "/opt/app/web/src/components/modals/manage-modals.tsx");
import(/* webpackMode: "eager" */ "/opt/app/web/src/lib/providers.tsx");
import(/* webpackMode: "eager" */ "/opt/app/web/src/providers/ReactQueryProvider.tsx");
import(/* webpackMode: "eager" */ "/opt/app/web/src/styles/globals.css");
import(/* webpackMode: "eager" */ "/opt/app/web/src/ui/container.tsx")