"use client"; // Ensure this is a client component

import { usePathname } from "next/navigation";
import Breadcrumb from "../common/breadcrumb";

export default function BreadcrumbWrapper() {
  const pathname = usePathname();

  // Define pages where Breadcrumb should be hidden
  const hideBreadcrumbPages = ["/izoleap-landing"];

  // Render Breadcrumb only if pathname is not in hideBreadcrumbPages
  if (hideBreadcrumbPages.includes(pathname)) return null;

  return <Breadcrumb />;
}
