"use client"; // Ensure this is a client component

import { usePathname } from "next/navigation";
import { lazy } from "react";

const Footer = lazy(() => import("./footer/footer")); // Adjust path if needed

export default function FooterWrapper() {
	const pathname = usePathname();

	// Define pages where Footer should be hidden
	const hideFooterPages = ["/izoleap-landing"];

	// Render Footer only if pathname is not in hideFooterPages
	if (hideFooterPages.includes(pathname)) return null;

	return <Footer />;
}
