"use client"; // Ensure this is a client component

import { usePathname } from "next/navigation";
import { lazy } from "react";

const Header = lazy(() => import("./header/header")); // Adjust path if needed

export default function HeaderWrapper() {
	const pathname = usePathname();

	// Define pages where Header should be hidden
	const hideHeaderPages = ["/izoleap-landing"];

	// Render Header only if pathname is not in hideHeaderPages
	if (hideHeaderPages.includes(pathname)) return null;

	return <Header /> ;
}
